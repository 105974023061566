/* website: 3562-mb-boucherville
 * created at 2022-03-21 10:30 by fbbreard
 */

// Import all makes and organization styles files
@import "../templates/makes/mercedes-benz.scss";
@import "../utils/icons.scss";


@include breakpoint(mobile){
    .widget-sr {
        &.groups__434.dealer__Mercedes-Benz {
            .sr-topbar-login{
                padding: 0;
            }
            .LoginBanner .login-content{
                padding-right: 0;
            }
            .LoginBanner .btn-login .label,
            .LoginBanner .btn-login icon{
                font-size: 13px;
            }
            .LoginBanner .LoginBannerWrapper{
                min-height: 40px;
                max-height: 40px;
                .login-content{
                    display: flex;
                    #button-login.btn-login{
                        margin-left: 0;
                        height: 40px;
                    }
                    #button-register.btn-register{
                        margin-left: 10px;
                        height: 40px
                    }
                }
            }
      }
    }
  }

.widget-sr{
    @include clearfix;
    width: 100%;
    &.groups__434.dealer__Mercedes-Benz{
        button#button-login:before{
            content: '';
        }
        .LoginBanner .LoginBannerWrapper{
            height: 40px;
            .login-content{
                display: flex;
                flex-direction: row;
                justify-content: flex-end;
                align-items: center;
            }
        }
    }
}

